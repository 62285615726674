<template>
    <div v-show="qiandao_visible">
        <el-dialog
            title="签到管理"
            :visible.sync="qiandao_visible"
            width="80%"
            :before-close="close">
            <div
                style="
                    width: 100%;
                    height: 60vh;
                    overflow-y: auto;
                    padding: 20px;
                    font-size: 16px;
                ">
                <div>
                    <el-button
                        size="medium "
                        type="primary"
                        @click="confirmMultip"
                        >签到</el-button
                    >
                </div>
                <el-table
                    ref="multipleTable"
                    :data="qiandaoData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    border
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" min-width="25%">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名" min-width="25%">
                    </el-table-column>
                    <el-table-column prop="name" label="状态" min-width="25%">
                        <template slot-scope="scope">
                            <el-tag
                                v-show="scope.row.status == 0"
                                type="warning"
                                >未报名</el-tag
                            >
                            <el-tag
                                v-show="scope.row.status == 1"
                                type="success"
                                >已报名</el-tag
                            >
                            <el-tag
                                v-show="scope.row.status == 2"
                                type="warning"
                                >已签到</el-tag
                            >
                            <el-tag
                                v-show="scope.row.status == 3"
                                type="success"
                                >请假</el-tag
                            >
                        </template>
                    </el-table-column>
                    <el-table-column align="right" label="操作" min-width="25%">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.status != 2"
                                size="mini"
                                type="primary"
                                @click="confirm(scope.row)"
                                >签到</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close()">取 消</el-button>
                <el-button type="primary" @click="close()">确 定</el-button>
            </span>
        </el-dialog>
        <div
            v-if="dialogVisible"
            style="
                background-color: rgba(0, 0, 0, 0.3);
                width: 100vw;
                height: 100vh;
                z-index: 9999;
                position: fixed;
                top: 0;
                left: 0;
            ">
            <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                width="30%"
                :before-close="closeTime">
                <el-date-picker
                    v-model="valueDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间">
                </el-date-picker>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeTime()">取 消</el-button>
                    <el-button type="primary" @click="submitQiandao"
                        >确 定</el-button
                    >
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        qiandao_visible: {
            type: Boolean,
            default: false,
        },
        qiandaoData: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            multipleSelection: [],
            dialogVisible: false,
            valueDate: "",
        };
    },
    mounted() {},
    methods: {
        close() {
            this.$parent.qiandao_visible = false;
            this.$refs.multipleTable.clearSelection();
        },
        closeTime() {
            this.dialogVisible = false;
            this.valueDate = "";
            this.$refs.multipleTable.clearSelection();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            this.pagesize = val;
        },
        handleCurrentChange(val) {
            this.inputpage = val;
        },
        confirm(row) {
            let that = this;
            console.log("meiyihang===", row);
            if (that.multipleSelection.length == 0) {
                this.$message({
                    showClose: true,
                    message: "请先选择",
                });
            } else {
                that.dialogVisible = true;
                that.multipleSelection = [row];
            }
        },
        confirmMultip() {
            let that = this;
            if (that.multipleSelection.length == 0) {
                that.$message({
                    showClose: true,
                    message: "请先选择",
                });
            } else {
                that.dialogVisible = true;
            }
        },
        submitQiandao() {
            let that = this;
            if (that.valueDate == "") {
                that.$message({
                    showClose: true,
                    message: "请选择签到时间",
                    customClass: "messageBox",
                });
            } else {
                let arr = this.multipleSelection.map((item) => {
                    return item.id;
                });
                let params = {
                    ids: arr.join(),
                    sign_time: that.valueDate,
                };
                that.$post({
                    url: "/api/invest_research/qiandao",
                    params: params,
                }).then((res) => {
                    that.close();
                    that.closeTime();
                });
            }
        },
    },
};
</script>

<style lang="scss">
.el-date-picker {
    z-index: 1000000 !important; /* 使用 !important 来确保覆盖其他样式 */
}
.messageBox {
    z-index: 1000000 !important; /* 使用 !important 来确保覆盖其他样式 */
}
</style>
